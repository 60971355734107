export default [
  {
    path: "/chats",
    name: "chats",
    component: () => import("@/modules/chat/views/ChatIndex"),
    meta: {
      pageTitle: "chats",
      breadcrumb: [{ text: "Communication" }, { text: "chats" }],
    },
  },
  {
    path: "/chats/:id",
    name: "show-chat",
    component: () => import("@/modules/chat/views/ChatShow"),
    meta: {
      pageTitle: "Chat",
      breadcrumb: [
        { to: { name: "chat" }, text: "Chats" },
        { text: "Chat Box", active: true },
      ],
    },
  },
  {
    path: "/broadcasts",
    name: "broadcasts",
    component: () => import("@/modules/chat/views/BroadcastIndex"),
    meta: {
      pageTitle: "Broadcasts",
      breadcrumb: [{ text: "Communication" }, { text: "broadcasts" }],
    },
  },
  {
    path: "/broadcasts/:id",
    name: "show-broadcast",
    component: () => import("@/modules/chat/views/BroadcastShow"),
    meta: {
      pageTitle: "Broadcast",
      breadcrumb: [
        { to: { name: "broadcasts" }, text: "Broadcast" },
        { text: "Chat Box", active: true },
      ],
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("@/modules/chat/views/Chat"),
    meta: {
      pageTitle: "My Chats",
      breadcrumb: [{ text: "Chat", active: true }],
    },
  },
  //{
  //  path: "/tickets/chats/:chat_id",
  //  name: "ticket_chat",
  //  component: () => import("@/views/chat/chat/show"),
  //  meta: {
  //    pageTitle: "Chat",
  //    breadcrumb: [
  //      { to: { name: "chat" }, text: "Chats" },
  //      { text: "Chat Box", active: true },
  //    ],
  //    contentRenderer: "sidebar-left",
  //    contentClass: "chat-application",
  //  },
  //},
];
